import React, { useEffect, useState } from "react";
import { isSavedUserInfo } from "../helpers";
import PG_FFC_CONTENT from "../components/fba-fee-calculator/index.content";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/pages/fba-fee-calculator/common/seo_fba-fee-calculator.png";
import HomeIntro from "../components/fba-fee-calculator/Intro";
import SectionOne from "../components/fba-fee-calculator/SectionOne";
import SectionTwo from "../components/fba-fee-calculator/SectionTwo";
import SectionThree from "../components/fba-fee-calculator/SectionThree";
import SectionFour from "../components/fba-fee-calculator/SectionFour";
import SectionFive from "../components/fba-fee-calculator/SectionFive";
import "../components/fba-fee-calculator/index.scss";

const PgFFC = () => {
  const { SecOne, SecTwo, SecFour, SecFive } = PG_FFC_CONTENT;

  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [switchedToFba, setSwitchedToFba] = useState(true);
  const [isShowVideo, setIsShowVideo] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (isSavedUserInfo() && mounted) {
      setIsSecondStateCollectEmail(true);
    }
    return function cleanup() {
      mounted = false;
    };
  }, [expanded]);
  return (
    <Layout
      bgHeader="#f4f7f9"
      isSecondStateCollectEmail={isSecondStateCollectEmail}
    >
      <Seo
        title="FREE Amazon FBA Calculator - Seller Revenue, Fees & Profit"
        description="Amazon FBA Calculator helps you to estimate seller`s profit & the revenue potential of any Amazon product. Learn everything about Amazon Fees and get our free app to calculate them automatically"
        page="fba-fee-calculator"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <div className="PgFFC">
        <HomeIntro
          setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
          isSecondStateCollectEmail={isSecondStateCollectEmail}
        />
        <SectionOne
          data={SecOne}
          isShowVideo={isShowVideo}
          setIsShowVideo={setIsShowVideo}
        />
        <SectionTwo
          data={SecTwo}
          switchedToFba={switchedToFba}
          setSwitchedToFba={setSwitchedToFba}
        />
        <SectionThree />
        <SectionFour
          data={SecFour}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <SectionFive data={SecFive} />
      </div>
    </Layout>
  );
};
export default PgFFC;
