import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import SectionTwoList from "./SectionTwoList";
import "./index.scss";

const SectionTwo = (props) => {
  const { data, switchedToFba, setSwitchedToFba } = props;

  const isMobile = useBreakpoint().mobMax;
  return (
    <section className="PgFFC-SectionTwo" id="secTwo">
      <div className="PgFFC-SectionTwo__container">
        <div className="PgFFC-SectionTwo__title">
          <h2 className="PgFFC__title PgFFC-SectionTwo__mainTitle">
            FBA vs FBM:
          </h2>
          <div className="PgFFC-SectionTwo__description">
            Which Fulfillment Method is Right for You?
          </div>
        </div>
        <div className="PgFFC-SectionTwo__text">
          <p>
            The Fulfillment by Amazon (FBA) program is very popular with sellers
            since it makes eCommerce selling extremely convenient. However, it
            also comes with a number of fees.
          </p>
          <p>
            Let’s go over both of Amazon’s fulfillment options and review what
            type of seller they’re best suited for.
          </p>
        </div>
      </div>
      {isMobile === undefined ? null : isMobile ? (
        switchedToFba ? (
          <>
            <div className="PgFFC-SectionTwo__container">
              <div className="PgFFC-SectionTwo__listSectionOne-title">
                <div className="PgFFC__title PgFFC-SectionTwo__mainTitle PgFFC-SectionTwo__listSectionOne-fba">
                  FBA
                </div>
                <div
                  className="PgFFC__title PgFFC-SectionTwo__mainTitle PgFFC-SectionTwo__listSectionOne-fbm"
                  onClick={() => setSwitchedToFba(false)}
                >
                  FBM
                </div>
              </div>
            </div>
            <div className="PgFFC-SectionTwo__dottedLine" />
            <div className="PgFFC-SectionTwo__container">
              <div className="PgFFC-SectionTwo__listSectionOne">
                <ul>
                  <SectionTwoList data={data} start={0} end={3} />
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="PgFFC-SectionTwo__container">
              <div className="PgFFC-SectionTwo__listSectionTwo-title">
                <div
                  className="PgFFC__title PgFFC-SectionTwo__mainTitle  PgFFC-SectionTwo__listSectionTwo-fba"
                  onClick={() => setSwitchedToFba(true)}
                >
                  FBA
                </div>
                <div className="PgFFC__title PgFFC-SectionTwo__mainTitle  PgFFC-SectionTwo__listSectionTwo-fbm">
                  FBM
                </div>
              </div>
            </div>
            <div className="PgFFC-SectionTwo__dottedLine" />
            <div className="PgFFC-SectionTwo__container">
              <div className="PgFFC-SectionTwo__listSectionTwo">
                <ul>
                  <SectionTwoList
                    data={data}
                    start={3}
                    end={data.list.length}
                  />
                </ul>
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <div className="PgFFC-SectionTwo__dottedLine" />
          <div className="PgFFC-SectionTwo__container">
            <div className="PgFFC-SectionTwo__list">
              <div className="PgFFC-SectionTwo__listSectionOne">
                <div className="PgFFC__title PgFFC-SectionTwo__mainTitle">
                  FBA
                </div>
                <ul>
                  <SectionTwoList data={data} start={0} end={3} />
                </ul>
              </div>
              <div className="PgFFC-SectionTwo__verticalLine" />
              <div className="PgFFC-SectionTwo__listSectionTwo">
                <div className="PgFFC__title PgFFC-SectionTwo__mainTitle">
                  FBM
                </div>
                <ul>
                  <SectionTwoList
                    data={data}
                    start={3}
                    end={data.list.length}
                  />
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="PgFFC-SectionTwo__dottedLine" />
      <div className="PgFFC-SectionTwo__container" />
    </section>
  );
};
SectionTwo.propTypes = {
  switchedToFba: PropTypes.bool,
  setSwitchedToFba: PropTypes.func,
  data: PropTypes.object,
};
export default SectionTwo;
