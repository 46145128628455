import React from "react";

const SectionFourTipsList = (props) => {
  const { data } = props;
  const res = [];
  for (let index = 0; index < data.tipsList.length; index++) {
    res.push(
      <li key={index}>
        <picture>
          <source media="(max-width: 767px)" srcSet={`${data.listImg[5]}`} />
          <source media="(min-width: 768px)" srcSet={`${data.listImg[0]}`} />
          <img src={data.listImg[0]} alt="" loading="lazy" />
        </picture>
        <p>
          {data.tipsList[index].beforeBoldText}
          <span>{data.tipsList[index].boldText}</span>
          {data.tipsList[index].afterBoldText}
        </p>
      </li>
    );
  }
  return res;
};
export default SectionFourTipsList;
