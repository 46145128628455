import sectionFourQuestion from "./SectionFourQuestion";

const SectionFourQuestionsList = (props) => {
  const { data, expanded, setExpanded } = props;
  const res = [];
  for (let index = 0; index < data.questionsList.length; index++) {
    res.push(sectionFourQuestion(index, data, expanded, setExpanded));
  }
  return res;
};
export default SectionFourQuestionsList;
