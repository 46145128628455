import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import PropTypes from "prop-types";
import SectionOneList from "./SectionOneList";
import "./index.scss";

const SectionOne = (props) => {
  const { data, isShowVideo, setIsShowVideo } = props;
  return (
    <section className="PgFFC-SectionOne" id="secOne">
      <div className="container">
        <h2 className="PgFFC__title PgFFC-SectionOne__mainTitle">
          Calculate the FBA fees for your product in minutes
        </h2>
        <div className="PgFFC-SectionOne__title">
          The FBA Calculator is a free tool that’s easy to use, making it a
          must-have for every seller
        </div>
        <div className="PgFFC-SectionOne__secYoutube">
          <div
            onClick={() => setIsShowVideo(true)}
            className={`PgFFC-SectionOne__Youtube ${
              isShowVideo ? "" : "screenPlay"
            } screenPlay_left`}
          >
            {isShowVideo ? (
              <iframe
                src="https://www.youtube.com/embed/j_YxcmHf_q4?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="youtube"
              />
            ) : (
              <picture className="PgFFC-SectionOne__Youtube-img">
                <source
                  type="image/webp"
                  srcSet={`${data.youtubeImg[2]} 1x, ${data.youtubeImg[3]} 2x`}
                />
                <img
                  srcSet={`${data.youtubeImg[0]} 1x, ${data.youtubeImg[1]} 2x`}
                  alt=""
                  loading="lazy"
                />
              </picture>
            )}
          </div>
          <div className="PgFFC-SectionOne__list">
            <div>Here’s how it works:</div>
            <ul>
              <SectionOneList data={data} />
            </ul>
          </div>
        </div>
        <div className="PgFFC-SectionOne__text">
          With these calculations, you can make an informed decision on whether
          an item is worth selling
        </div>
        <TrackedLink
          category="FbaFeeCalculatorLP"
          action="GetFbaCalculatorForFree"
          target
          class="PgFFC__btn"
          path="https://chrome.google.com/webstore/detail/amazon-fba-calculator-fre/dkgjopcolgcafhnicdahjemapkniikeh?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp"
        >
          GET FBA CALCULATOR FOR FREE
        </TrackedLink>
      </div>
    </section>
  );
};
SectionOne.propTypes = {
  expanded: PropTypes.number,
  setIsShowVideo: PropTypes.func,
  data: PropTypes.object,
};
export default SectionOne;
