import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import RedirectedButton from "./emailCollectRedirectedButton";
import "./StandardEmailCollect/index.scss";

const IntroEmailCollect = ({
  isShowText,
  title,
  page,
  buttonTextFirstState,
  buttonTextSecondState,
  customClass,
  placeholderInput,
  isLoading,
  emailValue,
  onEmailChange,
  onSubmit,
  onError,
  onKey,
  isAuth,
  formOpacity,
  redirectUrl,
}) => {
  const classes = [
    "emailView",
    "emailCollect",
    "emailCollect_green",
    `emailCollect-${PAGES[page].toLowerCase()}`,
    customClass,
  ]
    .filter(Boolean)
    .join(" ");

  return !isAuth ? (
    <div className={classes} style={{ opacity: formOpacity }}>
      <div className="emailCollect__container">
        <p className="emailCollect__title">
          {title} <span>No credit card required</span>
        </p>
        <div className="emailCollect-form emailCollect-form_green">
          <div className="emailCollect__control">
            <input
              type="email"
              placeholder={
                !onError
                  ? placeholderInput ||
                    "Enter your email to start your FREE TRIAL!"
                  : onError
              }
              className={`emailCollect__input emailCollect__input_green ${
                onError ? "error" : ""
              }`}
              value={emailValue}
              onChange={onEmailChange}
              onKeyDown={onKey}
            />
            <button
              type="button"
              className="bt emailCollect__bt emailCollect__bt_green"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {buttonTextFirstState}
            </button>
          </div>
          <p className="emailCollect__info emailCollect__info_green">
            By entering your email, you agree to receive marketing emails from
            AMZScout
          </p>
        </div>
      </div>
    </div>
  ) : (
    <RedirectedButton
      href={redirectUrl}
      title={buttonTextSecondState}
      isIntro
      isShowText={isShowText}
    />
  );
};

IntroEmailCollect.propTypes = {
  isAuth: PropTypes.bool,
  setIsSecondState: PropTypes.func,
  title: PropTypes.string,
  page: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  buttonTextSecondState: PropTypes.string,
  customClass: PropTypes.string,
  placeholderInput: PropTypes.string,
  onError: PropTypes.string,
  emailValue: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onKey: PropTypes.func,
  onSubmit: PropTypes.func,
  formOpacity: PropTypes.number,
  redirectUrl: PropTypes.string,
};

export default IntroEmailCollect;
