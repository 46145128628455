import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import IntroBlock from "./IntroBlock";
import "./index.scss";

const Intro = ({
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
  image,
}) => {
  const isMobile = useBreakpoint().tabMax;

  return (
    <section
      className={`PgFFC-Intro ${
        isSecondStateCollectEmail ? " " : "PgFFC-second"
      }`}
      id="fbaCalculator-home"
    >
      <div
        className={`PgFFC-Intro__container ${
          isSecondStateCollectEmail ? " " : "PgFFC-secondContainer"
        }`}
      >
        <div className="PgFFC-Intro__info">
          <div className="PgFFC-Intro__title PgFFC-doubleColor">
            <h1>Amazon FBA Calculator</h1>
            <span>
              Easily Calculate
              <br />
              Your Amazon FBA Fees
            </span>
          </div>
          <p className="PgFFC-Intro__description">
            Find products with the lowest fees and the best profit margins with{" "}
            <b>Amazon FBA Calculator</b> Chrome Extension
          </p>
        </div>
        <IntroBlock
          image={image}
          isMobile={isMobile}
          isSecondStateCollectEmail={isSecondStateCollectEmail}
          setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        />
      </div>
    </section>
  );
};
Intro.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default Intro;
